var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"users nj-grid"},[_c('div',{staticClass:"header"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.search()}}},[_c('div',{staticClass:"d-flex flex-row"},[_c('nj-chip-select-input',{staticClass:"mr-2",attrs:{"default-text":"Evento","items":_vm.eventsOptions},model:{value:(_vm.form.event),callback:function ($$v) {_vm.$set(_vm.form, "event", $$v)},expression:"form.event"}}),_c('nj-chip-select-input',{staticClass:"mr-2",attrs:{"default-text":"Rotina","items":_vm.modelsOptions},model:{value:(_vm.form.model),callback:function ($$v) {_vm.$set(_vm.form, "model", $$v)},expression:"form.model"}}),_c('nj-date-interval-input',{ref:"creationIntervalInput",staticClass:"mr-2",attrs:{"label":"Data de Cadastro","chip":"","hide-details":"","default-start-at":_vm.defaultStartAt,"default-ends-at":_vm.defaultEndsAt},on:{"change":_vm.chageCreationInterval}}),_c('nj-chip-text-input',{staticClass:"mr-2",attrs:{"default-text":"Cód. Usuário"},model:{value:(_vm.form.user),callback:function ($$v) {_vm.$set(_vm.form, "user", $$v)},expression:"form.user"}})],1)])],1),_c('v-data-table',{ref:"dataTable",staticClass:"nj-table",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":"Buscando...","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"page":_vm.pagination.current_page,"server-items-length":_vm.pagination.total_entries,"footer-props":{
      'disable-items-per-page': true,
      'items-per-page-all-text': '',
      'items-per-page-options': [30, -1],
      'items-per-page-text': ''
    }},on:{"update:page":function (page) { return _vm.search(page); },"update:sort-by":function (by) {if(by) {_vm.sortBy = by}},"update:sort-desc":function (desc) { return _vm.sortDesc = desc; }},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("createdAt")(item.created_at))+" ")]}},{key:"item.event",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("eventText")(item.event))+" ")]}},{key:"item.item_type",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("itemTypeText")(item.item_type))+" ")]}},{key:"item.controls",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticStyle:{"width":"160px"}},[_c('nj-audit-dialog',{attrs:{"item":item.item_type,"desc":_vm.logDesc(item),"item-id":item.item_id,"api-config":_vm.apiConfig},scopedSlots:_vm._u([{key:"open",fn:function(ref){
    var open = ref.open;
return [_c('v-btn',{attrs:{"title":"Auditoria","icon":""},on:{"click":open}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-magnify")])],1)]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }