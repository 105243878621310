<template>
  <div class="users nj-grid">
    <div class="header">
      <v-form @submit.prevent="search()">
        <div class="d-flex flex-row">
          <nj-chip-select-input
            class="mr-2"
            default-text="Evento"
            :items="eventsOptions"
            v-model="form.event"
          ></nj-chip-select-input>
          <nj-chip-select-input
            class="mr-2"
            default-text="Rotina"
            :items="modelsOptions"
            v-model="form.model"
          ></nj-chip-select-input>
          <nj-date-interval-input
            class="mr-2"
            label="Data de Cadastro"
            ref="creationIntervalInput"
            chip
            hide-details
            @change="chageCreationInterval"
            :default-start-at="defaultStartAt"
            :default-ends-at="defaultEndsAt"
          />
          <nj-chip-text-input
            class="mr-2"
            default-text="Cód. Usuário"
            v-model="form.user"
          ></nj-chip-text-input>
        </div>
      </v-form>
    </div>
    <v-data-table
      :headers="headers"
      :items="items"
      class="nj-table"
      :loading="loading"
      loading-text="Buscando..."
      ref="dataTable"
      @update:page="page => search(page)"
      @update:sort-by="by => {if(by) {sortBy = by}}"
      @update:sort-desc="desc => sortDesc = desc"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :page="pagination.current_page"
      :server-items-length="pagination.total_entries"
      :footer-props="{
        'disable-items-per-page': true,
        'items-per-page-all-text': '',
        'items-per-page-options': [30, -1],
        'items-per-page-text': ''
      }"
    >
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | createdAt }}
      </template>
      <template v-slot:item.event="{ item }">
        {{ item.event | eventText }}
      </template>
      <template v-slot:item.item_type="{ item }">
        {{ item.item_type | itemTypeText }}
      </template>
      <template v-slot:item.controls="{ item }">
        <div style="width: 160px">
          <nj-audit-dialog
            :item="item.item_type"
            :desc="logDesc(item)"
            :item-id="item.item_id"
            :api-config="apiConfig"
          >
            <template v-slot:open="{ open }">
              <v-btn
                @click="open"
                title="Auditoria"
                icon
              >
                <v-icon color="primary">mdi-magnify</v-icon>
              </v-btn>
            </template>
          </nj-audit-dialog>
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import moment from 'moment'
import Api from '@/resources/NajaSocialApi'
import LogsEnum from '@/enumerations/logs_enum'
const today = moment()

export default {
  name: 'Logs',

  watch: {
    form: {
      handler() {
        this.search()
      },
      deep: true,
    },

    sortDesc() {
      this.search()
    }
  },

  filters: {
    createdAt: function (value) {
      return moment(value).format('DD/MM/YYYY HH:mm:ss')
    },

    eventText: function(value) {
      return LogsEnum.events.find(x => value === x.value)?.text
    },

    itemTypeText: function(value) {
      return LogsEnum.models.find(x => value === x.value)?.text
    }
  },

  data() {
    return {
      searchTerm: '',
      loading: false,
      items: [],
      eventsOptions: LogsEnum.events,
      modelsOptions: LogsEnum.models,
      defaultStartAt: today.format('DD/MM/YYYY'),
      defaultEndsAt: today.format('DD/MM/YYYY'),
      sortDesc: true,
      sortBy: 'created_at',
      pagination: {
        current_page: 1,
        total_entries: 0,
      },
      apiConfig: {
        baseURL: Api.baseURL,
        apiKey: Api.access_key,
        authorization: this.$store.getters.token,
      },
      form: {
        created_at_gteq: null,
        created_at_lteq: null,
        event: null,
        model: null
      },
      headers: [
        { text: 'Dt/Hr da criação', value: 'created_at' },
        { text: 'Cód. Usuário', value: 'whodunnit', sortable: false },
        { text: 'Usuário', value: 'who', sortable: false },
        { text: 'Rotina', value: 'item_type', sortable: false },
        { text: 'Código', value: 'item_id' },
        { text: 'Evento', value: 'event', sortable: false },
        { text: 'Ações', value: 'controls', sortable: false },
      ],
    }
  },

  mounted() {
    console.log(this.$refs.dataTable)
  },

  methods: {
    // sort(field, direction) {
    //   console.log(field, direction)
    // },
    logDesc(item) {
      const model = LogsEnum.models.find(x => item.item_type === x.value)
      return `${model?.text} - ${item.item_id}`
    },
    chageCreationInterval(interval) {
      this.form.created_at_gteq = interval[0]
      this.form.created_at_lteq = interval[1]
    },

    search(page = null) {
      this.loading = true
      const sortDirection = this.sortDesc ? 'desc' : 'asc'
      const query = {
        page: page || 1,
        q: {
          s: [`${this.sortBy} ${sortDirection}`],
          event_eq: this.form.event,
          // item_type_eq: this.form.model,
          whodunnit_eq: this.form.user
        },
      }

      if (
        this.form.created_at_gteq != null &&
        this.form.created_at_lteq != null
      ) {
        query.q.created_at_gteq = `${this.form.created_at_gteq} 00:00`
        query.q.created_at_lteq = `${this.form.created_at_lteq} 23:59`
      }

      Api.versions
        .search(query)
        .then(response => {
          this.items = response.data.versions
          this.pagination = response.data.pagination
        })
        .catch(error => {
          if (error.response?.status === 400) {
            this.$root.$children[0].toast(
              error.response.data.error || error.response.data.warn
            )
          }
        })
        .finally(() => {
          this.loading = false
          this.$scrollTop()
        })
    },
  },
}
</script>

<style scoped>
.header .v-form {
  width: 100%;
  display: flex;
}
</style>
